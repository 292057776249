import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  ChevronLeft,
  KeyboardArrowDown,
  KeyboardArrowUp,
  VideoCameraFront,
} from '@mui/icons-material';
import { styled } from '@mui/material';

import { FeatureListItem, FeatureType } from '../../types';

import { useAppSelector } from '../../hooks';

import alertDashboard from '../../assets/images/svg/alert-dashboard.svg';
import areaManagementSvg from '../../assets/images/svg/area-management.svg';
import logSvg from '../../assets/images/svg/book-open.svg';
import projectSideBtn from '../../assets/images/svg/btn_projectSideBar.svg';
import cctvSvg from '../../assets/images/svg/cctv.svg';
import dasairSvg from '../../assets/images/svg/dasair.svg';
import dascasSvg from '../../assets/images/svg/dascasSvg.svg';
import dasconcreteSvg from '../../assets/images/svg/dasconcrete.svg';
import dasGasSvg from '../../assets/images/svg/dasgas.svg';
import dasloopSvg from '../../assets/images/svg/dasloop.svg';
import daspowerSvg from '../../assets/images/svg/daspower.svg';
import dastempSvg from '../../assets/images/svg/dastemp.svg';
import downloadSvg from '../../assets/images/svg/download.svg';
import gearSvg from '../../assets/images/svg/gear.svg';
import aiCameraSvg from '../../assets/images/svg/icon_ai-camera.svg';
import daslockSvg from '../../assets/images/svg/icon_daslock.svg';
import overviewSvg from '../../assets/images/svg/img_overview.svg';
import pipeSvg from '../../assets/images/svg/pipe.svg';
import plantSvg from '../../assets/images/svg/plant.svg';
import paSvg from '../../assets/images/svg/public-announcement.svg';
import reportSvg from '../../assets/images/svg/report_svg_icon.svg';
import tunnelSvg from '../../assets/images/svg/tunnel.svg';
import dastrackV from '../../assets/images/svg/vehicle.svg';
import AttendanceSvgIcon from '../../assets/SvgIcon/AttendanceSvgIcon';
import ConfinedSpaceSvgIcon from '../../assets/SvgIcon/ConfinedSpaceSvgIcon';
import HomeSvgIcon from '../../assets/SvgIcon/HomeSvgIcon';
import SafieSvgIcon from '../../assets/SvgIcon/third-party/SafieSvgIcon';
import IconContainer from '../IconContainer';
import DarkTooltip from '../Toolip/DarkTooltip';

import { Divider } from '.';

interface ExpandButtonProps {
  expand: boolean;
}

const ExpandButton = styled('div')<ExpandButtonProps>`
  & img {
    transform: ${({ expand }) => (expand ? 'rotate(180deg)' : 'rotate(0deg)')};
  }
  & :hover {
    opacity: 0.7;
  }
`;

const Container = styled('div')<ExpandButtonProps>`
  height: 100%;
`;

interface BasicMenuProps {
  dataFeatures: { [key in FeatureType]: FeatureListItem } | undefined;
  projectId: string | undefined;
  selectedProjectId: string | null;
  enterDelay: number;
  overviewReadable: boolean;
  reportReadable: boolean;
  alertReadable: boolean;
  areaWriteable: boolean;
  PAreadable: boolean;
  topScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  bottomScroll: {
    posDown: boolean;
    posUp: boolean;
    handleDown: () => void;
    handleTop: () => void;
    setRef: any;
  };
  onReset: () => void;
  expand: boolean;
  setExpand: () => void;
}

const BasicMenu: React.FC<BasicMenuProps> = ({
  dataFeatures,
  projectId,
  selectedProjectId,
  enterDelay,
  overviewReadable,
  reportReadable,
  alertReadable,
  areaWriteable,
  PAreadable,
  topScroll,
  bottomScroll,
  onReset,
  expand,
  setExpand,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation('sidebar');
  const { role: userProjectRole } = useAppSelector((store) => store.projects);
  const projectBar = document.getElementById('projectBar');

  return (
    <Container expand={expand}>
      <nav id="projectBar">
        {/^\/dashboard.*/.test(location.pathname) && (
          <div
            className="back"
            onClick={() => {
              onReset();
              navigate('/project');
            }}
          >
            <div>
              <ChevronLeft />
            </div>
          </div>
        )}
        <NavLink
          className="home"
          to={`/dashboard/${selectedProjectId}`}
          data-cy="nav-sidebar-home"
        >
          <div>
            <HomeSvgIcon sx={{ width: '32px', height: '32px' }} />
          </div>
          {expand && <span> Home</span>}
        </NavLink>
        <Divider sx={{}} />
        <div className="side-menu">
          <div>
            <div className="swtich-menu">
              {topScroll.posDown && (
                <IconContainer
                  onClick={topScroll.handleTop}
                  sx={{
                    width: '100%',
                    height: 'unset',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <KeyboardArrowUp />
                </IconContainer>
              )}
              <div
                className="switch-item"
                ref={(ref) => {
                  if (ref && ref.scrollHeight > ref.clientHeight) {
                    topScroll.setRef(ref);
                  }
                }}
              >
                {dataFeatures?.dasloop && (
                  <NavLink
                    to={`/dasloop-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dasloop"
                  >
                    <DarkTooltip
                      title={t('tooltip.dasloop-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dasloopSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dasloop-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dastrack && (
                  <NavLink
                    to={`/dastrack-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dastrack"
                  >
                    <DarkTooltip
                      title={t('tooltip.dastrack-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={plantSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dastrack-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dascas && (
                  <NavLink
                    to={`/dascas-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dascas"
                  >
                    <DarkTooltip
                      title={t('tooltip.dascas-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dascasSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dascas-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.daspower && (
                  <NavLink
                    to={`/daspower-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-daspower"
                  >
                    <DarkTooltip
                      title={t('tooltip.daspower-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={daspowerSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.daspower-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dastemp && (
                  <NavLink
                    to={`/dastemp-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dastemp"
                  >
                    <DarkTooltip
                      title={t('tooltip.dastemp-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dastempSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dastemp-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dastrack_v && (
                  <NavLink
                    to={`/dastrack-v-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dastrack-v"
                  >
                    <DarkTooltip
                      title={t('tooltip.dastrack-v-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dastrackV} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dastrack-v-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dasair && (
                  <NavLink
                    to={`/dasair-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dasair"
                  >
                    <DarkTooltip
                      title={t('tooltip.dasair-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dasairSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dasair-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.tunnel && (
                  <NavLink
                    to={`/tunnel-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-tunnel"
                  >
                    <DarkTooltip
                      title={t('tooltip.tunnel')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={tunnelSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.tunnel')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.attendance && (
                  <NavLink
                    to={`/multi-storey-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-attendance"
                  >
                    <DarkTooltip
                      title={t('tooltip.multi-storey')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <AttendanceSvgIcon />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.multi-storey')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dasgas && (
                  <NavLink
                    to={`/dasgas-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dasgas"
                  >
                    <DarkTooltip
                      title={t('tooltip.dasgas-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dasGasSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.dasgas-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.daswater && (
                  <NavLink
                    to={`/daswater-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dasair"
                  >
                    <DarkTooltip
                      title={t('tooltip.daswater-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={pipeSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.daswater-overview')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.cctv && (
                  <NavLink
                    to={`/cctv-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-cctv"
                  >
                    <DarkTooltip
                      title={t('tooltip.cctv')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={cctvSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.cctv')} </span>}
                  </NavLink>
                )}
                {(projectId === 'ff222130-8d6a-11ed-92b3-bba15f6c742a' ||
                  projectId === '3ab4e2a0-0729-11ed-a223-6dabad045fcf') && (
                  <NavLink
                    to={`/dascart-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-cctv"
                  >
                    {/* not translayion */}
                    <DarkTooltip
                      title={t('tooltip.inspection-vehicle')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <VideoCameraFront />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.inspection-vehicle')}</span>}
                  </NavLink>
                )}
                {dataFeatures?.daslock && (
                  <NavLink
                    to={`/daslock-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-daslock"
                  >
                    <DarkTooltip
                      title={t('tooltip.daslock')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={daslockSvg} />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.daslock')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.dasconcrete && (
                  <NavLink
                    to={`/concrete-sensor-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-concrete-sensor"
                  >
                    <DarkTooltip
                      title={t('tooltip.concrete-sensor')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={dasconcreteSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.concrete-sensor')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.confined && (
                  <NavLink
                    to={`/confined-space-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-dasloop"
                  >
                    <DarkTooltip
                      title={t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <ConfinedSpaceSvgIcon />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && (
                      <span>
                        {t('tooltip.ssss.ConfinedSpaceMonitoringSystem')}
                      </span>
                    )}
                  </NavLink>
                )}
                {dataFeatures?.das_ai_box && (
                  <NavLink
                    to={`/ai-camera/${selectedProjectId}`}
                    data-cy="nav-sidebar-ai-camera"
                  >
                    <DarkTooltip
                      title={t('ai-camera')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={aiCameraSvg} />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('ai-camera')} </span>}
                  </NavLink>
                )}
                {dataFeatures?.safie && (
                  <NavLink
                    to={`/safie-camera/${selectedProjectId}`}
                    data-cy="nav-sidebar-safie-camera"
                  >
                    <DarkTooltip
                      title={'Safie Camera'}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <SafieSvgIcon
                            viewBox="0 0 52 52"
                            width={23}
                            height={26}
                          />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>Safie Camera</span>}
                  </NavLink>
                )}
              </div>
              {topScroll.posUp && (
                <IconContainer
                  onClick={topScroll.handleDown}
                  sx={{
                    width: '100%',
                    height: 'unset',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <KeyboardArrowDown />
                </IconContainer>
              )}
              <div className="expand-bar">
                <Divider
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                />
                <div
                  onClick={() => {
                    setExpand();
                    projectBar?.classList.toggle('expand');
                  }}
                  className="button-container"
                >
                  <ExpandButton
                    expand={expand}
                    sx={{ width: 'fit-content', display: 'none' }}
                  >
                    <IconContainer
                      sx={{
                        width: 'fit-content',
                      }}
                    >
                      <img src={projectSideBtn} />
                    </IconContainer>
                  </ExpandButton>
                </div>
              </div>

              {bottomScroll.posDown && (
                <IconContainer
                  onClick={bottomScroll.handleTop}
                  sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
                >
                  <KeyboardArrowUp />
                </IconContainer>
              )}
              <div
                className="switch-item"
                ref={(ref) => {
                  if (ref && ref.scrollHeight > ref.clientHeight) {
                    bottomScroll.setRef(ref);
                  }
                }}
              >
                {overviewReadable && (
                  <NavLink
                    to={`/overview-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-overview"
                  >
                    <DarkTooltip
                      title={t('tooltip.project-overview')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={overviewSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.project-overview')} </span>}
                  </NavLink>
                )}
                {reportReadable && (
                  <NavLink
                    to={`/report-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-report"
                  >
                    <DarkTooltip
                      title={t('tooltip.report')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={reportSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.report')} </span>}
                  </NavLink>
                )}
                {alertReadable && (
                  <NavLink
                    to={`/alert-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-alert"
                  >
                    <DarkTooltip
                      title={t('tooltip.alert')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={alertDashboard} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.alert')} </span>}
                  </NavLink>
                )}
                {PAreadable && (
                  <NavLink
                    to={`public-announcement/${selectedProjectId}`}
                    data-cy="nav-sidebar-public-announcement"
                  >
                    <DarkTooltip
                      title={t('tooltip.public-announcement')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={paSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.public-announcement')} </span>}
                  </NavLink>
                )}
                {areaWriteable && (
                  <NavLink
                    to={`/area-management-dashboard/${selectedProjectId}`}
                    data-cy="nav-sidebar-area-management"
                  >
                    <DarkTooltip
                      title={t('tooltip.area')}
                      placement="right"
                      enterDelay={enterDelay}
                    >
                      <div>
                        <IconContainer className="icon">
                          <img src={areaManagementSvg} alt="" />
                        </IconContainer>
                      </div>
                    </DarkTooltip>
                    {expand && <span>{t('tooltip.area')} </span>}
                  </NavLink>
                )}
                {(userProjectRole === 'owner' || userProjectRole === 'admin') &&
                  dataFeatures?.report && (
                    <NavLink
                      to={`/data-download-dashboard/${selectedProjectId}`}
                      data-cy="nav-sidebar-data-download"
                    >
                      <DarkTooltip
                        title={t('tooltip.data-download')}
                        placement="right"
                        enterDelay={enterDelay}
                      >
                        <div>
                          <IconContainer className="icon">
                            <img src={downloadSvg} alt="" />
                          </IconContainer>
                        </div>
                      </DarkTooltip>
                      {expand && <span>{t('tooltip.data-download')} </span>}
                    </NavLink>
                  )}
              </div>
              {bottomScroll.posUp && (
                <IconContainer
                  onClick={bottomScroll.handleDown}
                  sx={{ width: '50px', height: 'unset', cursor: 'pointer' }}
                >
                  <KeyboardArrowDown sx={{ width: '100%' }} />
                </IconContainer>
              )}
            </div>
            <div className="setting">
              <NavLink
                to={`/log/${selectedProjectId}`}
                data-cy="nav-sidebar-log"
              >
                <DarkTooltip
                  title={t('tooltip.log')}
                  placement="right"
                  enterDelay={enterDelay}
                >
                  <div>
                    <IconContainer className="icon">
                      <img src={logSvg} alt="" />
                    </IconContainer>
                  </div>
                </DarkTooltip>
                {expand && <span>{t('tooltip.log')} </span>}
              </NavLink>
              <NavLink
                to={`/project-setting/${selectedProjectId}`}
                data-cy="nav-sidebar-project-setting"
                rel="noopener noreferrer"
              >
                <DarkTooltip
                  title={t('tooltip.project-setting')}
                  placement="right"
                  enterDelay={enterDelay}
                >
                  <div>
                    <IconContainer className="icon">
                      <img src={gearSvg} alt="" />
                    </IconContainer>
                  </div>
                </DarkTooltip>
                {expand && <span>{t('tooltip.project-setting')} </span>}
              </NavLink>
            </div>
          </div>
        </div>
      </nav>
    </Container>
  );
};

export default BasicMenu;
